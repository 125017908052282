import React from 'react';
import { Link } from 'gatsby';
import Scroll from './Scroll';

export default function Nav({ onMenuToggle = () => {} }) {
  return (
    <nav id="nav">
      <ul>
        <li className="special">
          <a
            href="#menu"
            onClick={e => {
              e.preventDefault();
              onMenuToggle();
            }}
            className="menuToggle"
          >            
          </a>
          <div id="menu">
            <ul>
              <li>
                <Scroll type="id" element="what-we-do">
                  <Link className="icon fa-check-circle" to="/#what-we-do"> What we do</Link>
                </Scroll>
              </li>
              <li>
                <Scroll type="id" element="principles">
                  <Link className="icon fa-hand-point-up" to="/#principles"> How we do it</Link>
                </Scroll>
              </li>
              <li>
                <Scroll type="id" element="expertise">
                  <Link className="icon fa-lightbulb" to="/#expertise"> Expertise</Link>
                </Scroll>
              </li>
              <li>
                <Scroll type="id" element="cta">
                  <Link className="icon fa-envelope" to="/#cta"> Contact us</Link>
                </Scroll>
              </li>
            </ul>
            <a
              className="close"
              onClick={e => {
                e.preventDefault();
                onMenuToggle();
              }}
              href="#menu"
            >
              {''}
            </a>
          </div>
        </li>
      </ul>
    </nav>
  );
}
