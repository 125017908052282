import React, { useState } from 'react';
import Nav from './Nav';
import { Link } from 'gatsby';
import Scroll from './Scroll';

export default function SideBar({ fullMenu }) {
  const [headerOpen, toggleHeader] = useState(false);
  return (
    <header id="header" className={`${fullMenu ? '' : 'alt'}`}> 
      
      <Scroll type="id" element="banner">
        <Link to="/" className="logo" />
      </Scroll>
      
      {/* 
      <Scroll type="id" element="one">
        <h1>
          <a href="#one">Explore</a>
        </h1>
      </Scroll>
      */}
      <div className={`${headerOpen ? 'is-menu-visible' : ' '}`}>
        <Nav onMenuToggle={() => toggleHeader(!headerOpen)} />
      </div>
    </header>
  );
}
